@import "../variables";
@import "skinFonts";
@import "skinIcons";

:root {
    /** ==================================================================== **/

    /** Create Custom CSS Variables from "sfra bootstrap override" variables **/

    /** ==================================================================== **/
    --color-white: #{$white};
    --color-black: #{$black};
    --color-blue: #{$blue};
    --color-green: #{$green};
    --color-primary: #1A818D;
    --color-primary-transparency: rgba(26, 129, 141, 0.25);
    --color-red: #{$red};
    --color-success: var(--color-green);
    --color-danger: var(--color-red);
    --color-light-blue: #f0f6fa;

    // Consolidated values
    --color-grey1: #{$grey1};
    --color-grey2: #{$grey2};
    --color-grey3: #{$grey3};
    --color-grey4: #{$grey4};
    --color-grey5: #{$grey5};
    --color-grey6: #{$grey6};
    --color-grey7: #{$grey7};
    --color-grey8: #{$grey8};
    --color-grey9: #f1f1f1;
    --color-grey10: #e3e3e3;
    --color-grey-transparent-1: rgba(0, 0, 0, 0.65);
    --color-grey-transparent-2: rgba(0, 0, 0, 0.25);
    --color-light-gray: var(--color-grey1);
    --color-slightly-darker-gray: var(--color-grey4);
    --color-dark-gray: var(--color-grey6);
    --color-darker-gray: var(--color-grey7);
    --color-horizontal-rule-grey: var(--color-grey3);
    --color-product-number-grey: var(--color-grey3);
    --color-horizontal-border-grey: var(--color-grey4);
    --color-menu-link: var(--color-grey6);
    --color-close-menu-bg: var(--color-grey2);
    --color-link-color: var(--color-dark-gray);
    --color-hr-border-color: var(--color-grey3);
    --color-sea-serpent: #51cbd9;

    /** ==================================================================== **/

    /** Define skin named variables here                                    **/

    /** ==================================================================== **/
    --skin-background-color-1: var(--color-gray1);
    --skin-background-color-2: var(--color-grey9);
    --skin-background-color-3: linear-gradient(180deg, #E3E3E3 0%, #F6F6F6 21.08%);
    --skin-border-color-1: var(--color-white);
    --skin-main-text-color-1: var(--ac-blue-text);
    --skin-main-text-color-2: var(--color-grey4);
    --skin-menu-color-1: #444;
    --skin-menu-color-1-invert: var(--color-white);
    --skin-menu-color-2: #222;
    --skin-link-color-1: #444;
    --skin-link-color-2: var(--color-primary);
    --skin-primary-color-1: var(--color-primary);
    --skin-primary-color-2: var(--color-sea-serpent);
    --skin-primary-color-invert-1: var(--color-white);
    --skin-primary-color-invert-2: var(--ac-blue-night);
    --skin-selectbox-background-color-1: var(--color-white);
    --skin-selectbox-text-color-1: var(--ac-blue-text);
    --skin-banner-background-color-1: #444;
    --skin-banner-background-color-2: #222;
    --skin-banner-text-color-1: var(--color-white);
    --skin-heading-color-1: var(--color-primary);
    --skin-heading-color-1-invert: var(--color-white);
    --skin-tab-background-color-1: var(--color-grey10);
    --skin-price-1: var(--ac-blue-text);
    --skin-header-font: 'roche_sans_regular';
    --skin-body-font: 'roche_sans_regular';
    --skin-bold-font: 'roche_sans_bold';
    --skin-medium-font: 'roche_sans_medium';
    --skin-image-background-color-1: linear-gradient(217.89deg, #26becf 54.82%, #8eb927 87.65%);
    --skin-scroll-blur-color-1: linear-gradient(270deg, var(--skin-primary-color-invert-1) 0%, transparent 100%);
    --skin-scroll-blur-color-2: linear-gradient(270deg, var(--skin-background-color-2) 0%, transparent 100%);
    --skin-shadow-1: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
