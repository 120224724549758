@font-face {
    font-family: 'roche_icons';
    src: url('../../fonts/roche-icons.eot');
    src: url('../../fonts/roche-icons.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/roche-icons.woff') format('woff'),
        url('../../fonts/roche-icons.ttf') format('truetype'),
        url('../../fonts/roche-icons.svg?#roche_icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.roche::before {
    content: "\e604";
    display: inline-block;
    font-family: 'roche_icons' !important;
    font-size: inherit;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -o-transform: scale(1);
}

.accu-chek::before {
    content: "\e600";
}

.facebook::before {
    content: "\e601";
}

.google-plus::before {
    content: "\e602";
}

.pinterest::before {
    content: "\e603";
}

.twitter::before {
    content: "\e605";
}

.import::before {
    content: "\e606";
}

// .cart:before {
// 	content: "\e607";
// }

.accu_close::before {
    content: "\e608";
}

.add::before {
    content: "\e609";
}

.demo::before {
    content: "\e60a";
}

.manual::before {
    content: "\e60b";
}

.register::before {
    content: "\e60c";
}

.support::before {
    content: "\e60d";
}

.article::before {
    content: "\e60e";
}

.tools::before {
    content: "\e60f";
}
