@font-face {
    font-family: 'roche_sans_regular';
    src: url('../../fonts/RocheSans-Regular.ttf');
    src: url('../../fonts/RocheSans-Regular.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-Regular.woff2')format('woff2'),
        url('../../fonts/RocheSans-Regular.woff')format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_italic';
    src: url('../../fonts/RocheSans-Italic.ttf');
    src: url('../../fonts/RocheSans-Italic.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-Italic.woff2')format('woff2'),
        url('../../fonts/RocheSans-Italic.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_light';
    src: url('../../fonts/RocheSans-Light.ttf');
    src: url('../../fonts/RocheSans-Light.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-Light.woff2')format('woff2'),
        url('../../fonts/RocheSans-Light.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_light_italic';
    src: url('../../fonts/RocheSans-LightItalic.ttf');
    src: url('../../fonts/RocheSans-LightItalic.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-LightItalic.woff2')format('woff2'),
        url('../../fonts/RocheSans-LightItalic.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'roche_sans_medium';
    src: url('../../fonts/RocheSans-Medium.ttf');
    src: url('../../fonts/RocheSans-Medium.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-Medium.woff2')format('woff2'),
        url('../../fonts/RocheSans-Medium.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_medium_italic';
    src: url('../../fonts/RocheSans-MediumItalic.ttf');
    src: url('../../fonts/RocheSans-MediumItalic.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-MediumItalic.woff2')format('woff2'),
        url('../../fonts/RocheSans-MediumItalic.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_bold';
    src: url('../../fonts/RocheSans-Bold.ttf');
    src: url('../../fonts/RocheSans-Bold.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-Bold.woff2')format('woff2'),
        url('../../fonts/RocheSans-Bold.woff')format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_bold_italic';
    src: url('../../fonts/RocheSans-BoldItalic.ttf');
    src: url('../../fonts/RocheSans-BoldItalic.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSans-BoldItalic.woff2')format('woff2'),
        url('../../fonts/RocheSans-BoldItalic.woff')format('woff');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'roche_sans_condensed_regular';
    src: url('../../fonts/RocheSansCondensed-Regular.ttf');
    src: url('../../fonts/RocheSansCondensed-Regular.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSansCondensed-Regular.woff2')format('woff2'),
        url('../../fonts/RocheSansCondensed-Regular.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_condensed_light';
    src: url('../../fonts/RocheSansCondensed-Light.ttf');
    src: url('../../fonts/RocheSansCondensed-Light.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSansCondensed-Light.woff2')format('woff2'),
        url('../../fonts/RocheSansCondensed-Light.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roche_sans_condensed_light_italic';
    src: url('../../fonts/RocheSansCondensed-LightItalic.ttf');
    src: url('../../fonts/RocheSansCondensed-LightItalic.ttf?#iefix')format('truetype'),
        url('../../fonts/RocheSansCondensed-LightItalic.woff2')format('woff2'),
        url('../../fonts/RocheSansCondensed-LightItalic.woff')format('woff');
    font-weight: normal;
    font-style: normal;
}
