@import "skinVariables";
@import "unifiedPortal";

.error-message p {
    margin-bottom: 0;
}

body,
.card,
.card-header,
.hero {
    background-color: var(--skin-background-color-1);
    font-family: var(--skin-body-font);
    color: var(--skin-main-text-color-1);
}

.card {
    border-style: none;
    border-radius: 30px;
    background-color: var(--skin-background-color-2);
    box-shadow: var(--skin-shadow-1);

    &-body,
    &-footer {
        background-color: unset;
        padding: 24px 0;
        margin-inline: 30px;

        >*:last-child,
        >*:last-child p {
            margin-bottom: 0;
        }
    }

    &-header {
        background-color: transparent;
        padding: 24px 30px;
        border: none;

        ~.card-body {
            border-top: 1px solid var(--grey-05);
        }
    }

    &__small {
        border-radius: 20px;

        .card-header {
            padding-inline: 20px;
        }

        .card-body,
        .card-footer {
            margin-inline: 20px;
        }
    }

    @media (max-width: 543.98px) {
        margin-inline: -20px;
        border-radius: 0;
        box-shadow: none;

        &-header {
            padding-inline: 20px;
        }

        &-body,
        &-footer {
            margin-inline: 20px;
        }
    }
}

.main-menu {
    // background-color: var(--skin-banner-background-color-1);
    background-color: var(--skin-white);
}

.banner-color {
    background-color: var(--skin-banner-background-color-1);
}

.header-banner {
    background-color: var(--skin-banner-background-color-2);
    color: var(--skin-banner-text-color-1);
}

.header-banner .close-button .close {
    background-color: var(--skin-primary-color-1);
}

h1.page-title {
    display: block;
    left: 0;
    background-color: transparent;
    color: var(--ac-neutrals-black);
    padding: 15px 25px 15px 0;
    margin-block: 0.5em;
    font-size: 2.5rem;

    @media (max-width: 768.98px) {
        display: block;
        text-align: center;
        padding: 5px 0;
        font-size: 2.375rem;
    }
}


h1.page-title::before {
    content: none;
}

.product-tile {
    background-color: var(--skin-background-color-1);
    border: 10px solid var(--skin-border-color-1);
}

.custom-select {
    background-color: var(--skin-selectbox-background-color-1);
    color: var(--skin-selectbox-text-color-1);
    height: calc(1.5em + 1.5rem + 2px);
    padding-block: 0.75rem;
    padding-right: 2.5rem;
    border-radius: 10px;
    border-color: var(--grey-03);

    &:focus {
        border-color: var(--grey-03);
        box-shadow: none;
    }
}

a {
    color: var(--skin-link-color-1);

    &.link-primary {
        color: var(--skin-primary-color-1);
    }

    &.external-link::after {
        content: '';
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        vertical-align: middle;
        background: url('../../images/external-link.svg') no-repeat;
    }
}

.dropdown-menu,
.dropdown-menu a,
.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a {
    // TODO - review
    // color: var(--skin-menu-color-1);
    color: var(--skin-menu-color-2);

    @media (min-width: 769px) {
        // stylelint-disable-line
        // color: var(--skin-menu-color-1-invert);
        color: var(--skin-menu-color-2);
    }
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    background-color: var(--skin-selectbox-background-color-1);

    a,
    .nav-item {
        color: var(--skin-selectbox-text-color-1);
    }
}

header~#maincontent .container a:not(.btn-primary) {
    color: var(--skin-primary-color-1);
}

.price {
    color: var(--skin-price-1);
}

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
    color: var(--skin-selectbox-text-color-1);
}

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: transparent;
    color: var(--skin-menu-color-1);
}

.btn-primary {
    background: var(--skin-primary-color-1);
    border-radius: 40px;
    padding: 10px 32px;
    box-shadow: none;
    color: var(--skin-primary-color-invert-1);
    border: 1px solid var(--skin-primary-color-1);
    white-space: nowrap;
    -webkit-transition: background-color ease-in-out 0.5s;
    -moz-transition: background-color ease-in-out 0.5s;
    -o-transition: background-color ease-in-out 0.5s;
    transition: background-color ease-in-out 0.5s;

    &.disabled,
    &:disabled {
        background-color: var(--skin-primary-color-1);
        border-color: var(--skin-primary-color);
        color: var(--skin-primary-color-invert-1);
        opacity: 0.5;
    }

    &:not(:disabled):hover:not(.disabled),
    &:not(:disabled):focus:not(.disabled),
    &:not(:disabled):active:not(.disabled) {
        background-color: var(--skin-primary-color-invert-2);
        box-shadow: 0 0 4px var(--skin-primary-color-invert-1);
        border: 1px solid var(--skin-primary-color-invert-2);
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--skin-header-font), sans-serif;
}

.btn-outline-primary {
    --bs-btn-color: #003cc5;
    --bs-btn-border-color: #003cc5;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #003cc5;
    --bs-btn-hover-border-color: #003cc5;
    --bs-btn-focus-shadow-rgb: 0, 60, 197;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #003cc5;
    --bs-btn-active-border-color: #003cc5;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #003cc5;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #003cc5;
    --bs-gradient: none;

    background-color: var(--skin-primary-color-invert-1);
    border: 1px solid var(--skin-primary-color-1);
    border-radius: 30px;
    text-decoration: none;
    color: var(--skin-primary-color-1);
    padding: 10px 32px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    white-space: nowrap;
    -webkit-transition: background-color ease-in-out 0.5s;
    -moz-transition: background-color ease-in-out 0.5s;
    -o-transition: background-color ease-in-out 0.5s;
    transition: background-color ease-in-out 0.5s;

    &:disabled,
    &.disabled {
        color: var(--skin-primary-color-1);
    }

    &:not(.disabled, :disabled) {
        &.active,
        &:active {
            background-color: var(--skin-primary-color-1);
            color: var(--skin-primary-color-invert-1);

            /* stylelint-disable-next-line no-descending-specificity */
            a {
                color: var(--skin-primary-color-1);
            }
        }

        &:hover {
            background-color: var(--skin-primary-color-1);
            color: var(--skin-primary-color-invert-1);

            /* stylelint-disable-next-line no-descending-specificity */
            a {
                color: var(--skin-primary-color-invert-1);

                /* stylelint-disable-next-line no-descending-specificity */
                &:hover {
                    color: var(--skin-primary-color-invert-1);
                    text-decoration: none
                }
            }
        }
    }
}

.btn-link,
.btn-link:hover {
    color: var(--skin-link-color-2);
    border-width: 0;
}

.ac-blue-text {
    color: var(--skin-main-text-color-1);

    @media (max-width: 768.98px) {
        text-align: center;
    }
}

.ac-bold {
    font-family: var(--skin-bold-font);
}

.ac-header {
    font-family: var(--skin-header-font);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
    font-weight: 400;
    line-height: 1.1;
    font-family: var(--skin-medium-font), Arial, "Arial Narrow";
    margin-bottom: 0.75rem;
    color: var(--skin-primary-color-1);

    @media (min-width: 769px) {
        margin-bottom: 1rem;
    }
}

h1,
.h1 {
    color: var(--ac-neutrals-black);
    font-size: 2.375rem;
    line-height: 1.1;

    @media (min-width: 769px) {
        font-size: 2.75rem;
        line-height: 1;
    }

    @media (min-width: 992px) {
        font-size: 4rem;
    }
}


.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: $svg-radio;
}

.custom-radio .custom-control-label::before {
    background: transparent;
    border-color: var(--skin-main-text-color-1);
}

.custom-checkbox {
    .custom-control-label::before {
        border-color: var(--skin-primary-color-1);
        background: var(--skin-primary-color-invert-1);
    }

    .custom-control-input:checked~.custom-control-label::after {
        background-color: var(--skin-primary-color-1);
        border-radius: 3px;
    }
}

.custom-control-input {
    &:checked~.custom-control-label::before {
        background: transparent;
        border-color: var(--skin-main-text-color-1);
    }

    &:focus {
        ~.custom-control-label::before {
            box-shadow: none;
        }

        &:not(:checked)~.custom-control-label::before {
            border-color: var(--skin-primary-color-1);
        }
    }

    &:not(:disabled):active~.custom-control-label::before {
        color: var(--skin-primary-color);
        background-color: transparent;
        border-color: var(--skin-primary-color-1);
    }
}

.form-group.required .form-control-label::before {
    color: inherit;
}

.form-control {
    background-color: var(--skin-selectbox-background-color-1);
    color: var(--skin-main-text-color-1);
    border-radius: 10px;
    border-color: var(--grey-03);

    &:focus {
        border-color: var(--grey-03);
        box-shadow: none;
    }
}

.modal-content {
    border-radius: 20px;
}

.availability,
.line-item-availability > .line-item-attributes {
    color: var(--color-red);
}

.disabled,
:disabled {
    cursor: not-allowed;
}

#product-search-results {
    @media (min-width: 769px) {
        padding-inline: 0;
    }
}

.slant-down::after {
    content: none;
}

.hero {
    width: 100%;
    max-height: 33vh;
    max-height: 33dvh;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: left 66%;
    object-position: left 66%;
}

.error-page-content {
    margin-block: 3.125rem;
}

.small-flex-gap {
    gap: 8px;
}
