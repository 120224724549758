:root {
    --white: #fff;
    --ac-blue-text: #0b393e;
    --ac-blue-night: #156771;
    --ac-blue-shade: #1a818d;
    --ac-blue-dark: #1e98a6;
    --ac-neutrals-black: #2a2a2a;
    --blue-01: #2a64c5;
    --blue-02: #E9F9FA;
    --blue-03: #F4FCFD;
    --blue-hover: #0c46a7;
    --grey-01: #28282c;
    --grey-02: #3a3a41;
    --grey-03: #727272;
    --grey-04: #b1b3b3;
    --grey-05: #e4e4e4;
    --grey-06: #f8f8f8;
    --mysugar-01: #617e1a;
    --mysugar-02: #ddeabe;
    --mysugar-03: #f9fcf4;
    --bs-blue: #003cc5;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-black: #000;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #003cc5;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 0, 60, 197;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: sfmono-regular, menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --bs-border-radius: 0.375rem;
    --bs-border-radius-sm: 0.25rem;
    --bs-border-radius-lg: 0.5rem;
    --bs-border-radius-xl: 1rem;
    --bs-border-radius-2xl: 2rem;
    --bs-border-radius-pill: 50rem;
    --bs-link-color: #003cc5;
    --bs-link-hover-color: #00309e;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
    --bs-popover-zindex: 1070;
    --bs-popover-max-width: 276px;
    --bs-popover-font-size: 0.875rem;
    --bs-popover-bg: #fff;
    --bs-popover-border-width: 1px;
    --bs-popover-border-color: var(--bs-border-color-translucent);
    --bs-popover-border-radius: 20px;
    --bs-popover-inner-border-radius: calc(0.5rem - 1px);
    --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-popover-header-padding-x: 1rem;
    --bs-popover-header-padding-y: 0.5rem;
    --bs-popover-header-font-size: 1rem;
    --bs-popover-header-bg: #f0f0f0;
    --bs-popover-body-padding-x: 1rem;
    --bs-popover-body-padding-y: 1rem;
    --bs-popover-body-color: #212529;
    --bs-popover-arrow-width: 1rem;
    --bs-popover-arrow-height: 0.5rem;
    --bs-popover-arrow-border: var(--bs-popover-border-color);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background-color: var(--grey-06);
    border: 1px solid #cacaca;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--grey-03);
}

.sticky {
    position: sticky;
    top: 0;
    z-index: 99;
    margin-bottom: 0;

    @media (min-width: 992px) {
        top: -1px;
    }
}

.bg-grad {
    display: none;
    position: absolute;
    min-height: 72px;
    background: linear-gradient(180deg, #e3e3e3 0%, #ebebeb 100%);
}

.suggestions {
    border-radius: 20px;
    max-width: 1440px;
    width: 100%;
    left: 0;
    position: absolute;

    .sgg-header {
        color: $grey4;
        font-size: 0.875em;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0.625em;
    }

    .sgg {
        border-bottom: 1px solid var(--grey-05);
        color: var(--grey-02);
        padding: 10px 2rem;
    }

    .sgg-container {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    .sgg-link {
        color: var(--grey-02) !important;
        text-decoration: none !important;
    }
}

.suggestions-wrapper {
    position: absolute;
    width: 100%;
}

.minicart {
    .cart {
        padding-top: 0.625em;
        padding-bottom: 0.625em;
        background-color: $body-bg;
        border-radius: var(--bs-popover-border-radius);
    }

    .card {
        margin-bottom: 1em;
        border-radius: 20px;

        &-body {
            margin: 0;
        }
    }

    .product-image {
        height: auto !important;
    }
}

.quantity-form {
    margin-inline: 1em;
    flex-basis: 100%;
    margin-top: 8px;
}

.quantity-label {
    margin-bottom: .5em;
}

.unified-header {
    nav.tbm-main ul {
        flex-wrap: nowrap;
    }

    .tbm-link.level-1 {
        white-space: nowrap;
    }
}

.line-item {
    &-quantity {
        display: flex;
        justify-content: center;
    }

    &-price,
    &-total-price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
}

@media (min-width: 992px) {
    .bg-grad {
        display: block;
    }

    .unified-header .header-main {
        padding-left: 0;
    }
}

.ot-sdk-show-settings {
    display: block;
    color: var(--grey-03);
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:focus,
    &:hover {
        color: var(--skin-menu-color-1);
    }
}
